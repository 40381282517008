import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { keyframes } from 'styled-components';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQuery.tabletPortrait`
    flex-direction: column;
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(8)}
    justify-content: left;
    height: 80%;
    width: 100%;
  `}
`;

export const Heading = styled.h1`
  ${fonts.headingMedium}
  margin: ${spacing(3)} 0 0 ${spacing(2)};
  text-align: center;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(4)} 0 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(7)};
  `}
`;

const Divider = styled(FlexibleWidthDivider)`
  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const TopDivider = styled(Divider)`
  margin-top: ${spacing(3)};
`;

export const BottomDivider = styled(Divider)`
  margin-bottom: ${spacing(8)};
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-top: ${spacing(5)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(8)};
    `}

    ${mediaQuery.tabletLandscape`
      margin: ${spacing(2)} 0;
    `}
  }
`;

export const HeadingGridItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    display: flex;
    align-items: center;
  `}
`;

export const WhyChooseUsGridItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    padding: 0 ${spacing(4)};
  `}
`;

// const counterClockwiseSpin = keyframes`
//   0% {
//     transform: rotate(360deg);
//   }

//   100% {
//     transform: rotate(0deg);
//   }
// `;

const clockwiseSpin = keyframes`
  0% {
    transform: scaleX(-1) rotate(360deg);
  }

  100% {
    transform: scaleX(-1) rotate(0deg);
  }
`;

export const StyledSpinner = styled.img`
  animation: ${clockwiseSpin} 1s linear infinite;
  transition: opacity 0.3s ease;
  transform-origin: 55% 50%;
  width: 80px;
  height: 80px;
`;

import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { FooterAnchorTextStyle } from '@rsa-digital/evo-shared-components/theme/JohnLewis/helpers/commonStyles';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const ComplianceFooterContainer = styled(PageWidthContainer)`
  background-color: ${colors.core04};

  padding-top: ${spacing(3.5)};

  padding-bottom: ${spacing(5)};

  ${mediaQuery.tabletPortrait`
    padding-top: ${spacing(5)};
  `};

  ${mediaQuery.tabletLandscape`
    padding-bottom: ${spacing(6)};
  `};
`;

export const RichTextWithModalAndMargins = styled(RichTextWithModal)`
  &&& {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li {
      color: ${colors.core02};
      ${FooterAnchorTextStyle}
    }

    ${FooterAnchorTextStyle}
  }

  && h2 {
    ${fonts.headingXSmall};

    margin-bottom: ${spacing(3)};
  }

  *:first-child {
    margin-top: 0;

    margin-bottom: ${spacing(4)};

    ${mediaQuery.tabletPortrait`
      margin-bottom: 0;
    `};
  }

  p + p {
    margin-top: ${spacing(2)};

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(2.5)};
    `};
  }

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
  `};
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-top: 0;
    margin-bottom: 0;
  }

  flex-flow: column-reverse;

  ${mediaQuery.tabletPortrait`
    flex-flow: row;
  `};
`;

import SecondaryAccordion from '@rsa-digital/evo-shared-components/components/Accordion/SecondaryAccordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { TableCell } from '@rsa-digital/evo-shared-components/components/Table';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/JohnLewis/colors';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled, { css } from 'styled-components';

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};
  margin-bottom: ${spacing(3)};
  margin-top: ${spacing(1)};

  ${mediaQuery.desktop`
    margin-top: ${spacing(3.5)};
    margin-bottom: ${spacing(4)};
  `}
`;

export const TableWrapper = styled.div`
  margin-top: ${spacing(2)};
  flex-direction: column;

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
  `}
`;

export const TableTitle = styled.div`
  ${fonts.paragraphLarge}
  && {
    font-weight: ${heavyFontWeight};
  }
`;

export const TableSubTitle = styled.p`
  && {
    white-space: normal;
  }

  &&&& {
    margin: ${spacing(0.5)} auto;
    color: ${colors.core01};
  }
  ${mediaQuery.tabletPortrait`
    && {
      white-space: nowrap;
      ${fonts.paragraph}    
    }
    `}
`;

const cellStyling = css`
    ${fonts.paragraphSmall}
    padding-left: ${spacing(1)};
    padding-right: ${spacing(1)};
    &&& {
      border-left: 1px solid  ${colors.neutral03};
      border-top: 1px solid  ${colors.neutral03};
    }
    ${mediaQuery.tabletPortrait`
      padding: ${spacing(1.5)} ${spacing(2)};
    `}
`;

export const PaddedTableCell = styled(TableCell)`
  ${cellStyling}
  ${fonts.paragraph};
  height: ${spacing(7)};
  width: 33.33%;
  & :last-child {
    border-right: 1px solid ${colors.neutral03};
  }
`;

export const PaddedTableHeaderCell = styled(TableCell)`
  ${cellStyling}
  height: ${spacing(11.25)};
  && {
    padding: ${spacing(1.5)};
  }
  &&&& {
    line-height: 1.55rem;
  }
  & :last-child {
    border-right: 1px solid  ${colors.neutral03}
  }
  ${mediaQuery.tabletPortrait`
    height: ${spacing(10)};
  `}
`;

export const ColumnHeadingCell = styled(TableCell)`
  ${cellStyling}
  height: ${spacing(8)};
  text-align: center;
  div {
    ${fonts.paragraph};
    & {
      font-weight: ${heavyFontWeight};
    }
    text-align: center;
  }
`;

export const RowHeadingCell = styled(TableCell)`
  ${cellStyling}
  div {
    word-break: break-word;
    max-width: fit-content;
    ${fonts.paragraph};
    text-align: center;
    margin: auto;
    & {
      font-weight: ${heavyFontWeight};
    }
  }

  ${mediaQuery.tabletPortrait`
    div {
      word-break: normal;
    }
  `}
`;

export const MobileAccordion = styled(SecondaryAccordion)`
  margin-bottom: ${spacing(2)};
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(1)};
    margin-bottom: ${spacing(1)};
  `}

  ${mediaQuery.desktop`
    margin-top: 0;
    display: none;
  `}

  table {
    table-layout: fixed;
    margin-top: ${spacing(2)};
    margin-bottom: ${spacing(1)};
  }

  table :last-child {
    margin-bottom: auto;
  }

  thead tr:first-child th {
    background: ${colors.core06};
    ${TableTitle} {
      color: ${colors.core01};
      line-height: 32px;
    }
    ${TableSubTitle} {
      ${fonts.paragraphSmall}
      & {
        line-height: 28px;
      }

      && {
        white-space: normal;
      }
    }
  }
  tbody {
    tr:last-child {
      td {
        border-right: 1px solid ${colors.neutral03};
        border-bottom: 1px solid ${colors.neutral03};
      }
    }
  }
`;

export const DesktopAccordion = styled(SecondaryAccordion)`
  display: none;

  ${mediaQuery.desktop`
    display: flex;
  `}

  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(1)};

  ${TableWrapper} {
    display: flex;
  }

  table {
    table-layout: auto;
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(1)};  
    max-width: 544px;
  }

  table :last-child {
    margin-bottom: auto;
  }
  
  thead tr:first-child th {
    background : ${colors.core06};
    ${TableTitle} {
        color: ${colors.core01};
        line-height: 28px;
    }
    ${TableSubTitle} {
        color: ${colors.core01};
           line-height: 24px;
    }
  }

  tbody td {
    div {
      min-height: 2.5rem;
      align-items: center;
    }
  }
`;

export const GridWithBottomMargin = styled(Grid)`
  & {
    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(3)};
    `}

    ${mediaQuery.desktop`
      margin-bottom: ${spacing(4)};
    `}
  }
`;

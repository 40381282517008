import Image from '@rsa-digital/evo-shared-components/components/Image';
import { ImageStyle } from '@rsa-digital/evo-shared-components/components/Image/styles';
import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Hero = styled.h1<{ alignLeft?: boolean }>`
  ${fonts.headingLarge}
  && {
    text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  }
  margin: ${spacing(3)} 0 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  padding: ${spacing(2)} 0;
  justify-content: center;

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)} 0;
  `}
`;

export const IconContainer = styled(PageWidthContainer)`
  border-bottom: solid 1px ${colors.core01};
`;

export const BackgroundContainer = styled(PageWidthContainer)`
  & > ${IconContainer} + ${Hero} {
    margin-top: ${spacing(5)};

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(7)};
    `}
  }
`;

export const SizedImage = styled(Image)`
  ${ImageStyle} {
    height: ${spacing(5)};
    width: auto;
  }
  height: ${spacing(5)};
`;

export const Subheading = styled.p`
  ${fonts.paragraphLarge}
  margin: ${spacing(3)} 0 0;

  ${mediaQuery.tabletPortrait`
    margin: 0 0 ${spacing(4)};
  `}
`;
